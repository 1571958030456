<template>
  <div style="background-color: #fff !important;">
    <v-form ref="checklistRenderForm">
      <v-row class="mx-0">
        <v-col cols="12" lg="4" sm="6" class="mb-0 pb-0">
          <v-text-field dense readonly hide-details v-model="templateName" outlined :label="$t('message.checklist.templateName')"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" sm="6" class="mb-0 pb-0">
          <v-text-field dense hide-details  v-model="checklistTitle" outlined :label="$t('message.deviation.title')"></v-text-field>
        </v-col>
         <v-col cols="12" lg="4" sm="6" class="mb-0 pb-0">
          <v-text-field dense hide-details  v-model="report_header" outlined :label="$t('message.checklist.report_header')"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" sm="6" class="mb-0 pb-0" v-if="can_send_email==1">
         <!--  <v-combobox dense multiple v-model="checklistRecipients" small-chips deletable-chips outlined :label="$t('message.checklist.checklist_recipients')" persistent-hint></v-combobox> -->
          <v-combobox
           class="mb-3"
            v-model="checklistRecipients"
            :items="emailRecipients"
            :search-input.sync="search_email"
            :label="$t('message.checklist.checklist_recipients')"
            hide-selected
            multiple
            persistent-hint
            outlined
            small-chips
            deletable-chips
            dense
          ></v-combobox>
        </v-col>
      </v-row>
       <v-row v-if="checklistProjectData" >
          <v-col cols="12" lg="3" md="4" xs="12"   :key="index" v-for="(projectdata, index) in (checklistProjectData) " >
          <v-text-field
          :value="projectdata"
          :label="$t('message.checklist.' + index)"
          persistent-hint
          readonly
          dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="12" class="pa-0">
          <v-row class="mx-0" v-if="!usersListSet">
            <v-col cols="12">
              <v-progress-linear color="primary accent-4" indeterminate rounded height="6"></v-progress-linear><!-- fieldsList.length < 1 -->
            </v-col>
          </v-row>
          <form-render
            v-else
            :checklistId="checklistId"
            :formId="formId"
            @back="backToList"
            @update="onSuccessHandler"
            :key="reInit"
            :token="accessToken"
            class="pa-1"
            :module = "module"
            :projectChecklistFormData="projectChecklistFormData"
            :tenantUsers = "tenantUsers"
            :parentFormRef="$refs.checklistRenderForm"
            :selectedProjectId="selectedProjectId"
            :dataGridImportValue="dataGridImportValue"
            :checklistData="checklistData"
            :checklistTemplateData="checklistTemplateData"
            >
          </form-render>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/* eslint-disable */
import FormRender from './ChecklistForm.vue'
import { hostAppApi } from '../../plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  props: ['formId', 'formName', 'checklistId', 'module', 'propId', 'selectedItemId', 'checklistName', 'projectChecklistFormData', 'selectedProjectId', 'checklistEmail', 'canSendEmail', 'dataGridImportValue', 'checklistData', 'checklistTemplateData', 'moduleType', 'moduleID'],
  data () {
    return {
      reInit: 0,
      accessToken: `Bearer ${this.$cookie.get(process.env.VUE_APP_HOST_TOKEN)}`,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      checklistTitle: '',
      checklistRecipients:'',
      can_send_email: 0,
      listOfTenantUsers: [],
      usersListSet: false,
      templateName: this.formName,
      checklistProjectData: null,
      report_header:'',
      emailRecipients: [],
      search_email: ''
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId']),
    tenantUsers () {
      return this.listOfTenantUsers || []
    }
  },
  components: {
    'form-render': FormRender
  },
  mounted () {
    this.checklistTitle = this.checklistName
    this.checklistRecipients = this.checklistEmail ? this.checklistEmail.split(',') : []
    this.can_send_email = this.canSendEmail
    if(this.checklistData)
    this.report_header=this.checklistData.report_header ? this.checklistData.report_header : this.checklistData.checklist_template_name
    if(!this.report_header && this.checklistTemplateData.title)
    this.report_header = this.checklistTemplateData.title
    this.getTenantUsers()
    this.getChecklistById()
  },
  methods: {
    getTenantUsers () {
      hostAppApi.get(`${this.getHostRefApi}get-active-users`)
        .then((response) => {
          if (response && response.data) {
            this.listOfTenantUsers = response.data || []
          }
        })
        .finally(() => {
          this.usersListSet = true
        })
    },
    getChecklistById () {
      if (this.checklistId && (this.$route.params.project_id || this.selectedProjectId)) {
        const projectId = this.$route.params.project_id  ? this.$route.params.project_id  : this.selectedProjectId
        hostAppApi.get(`${this.getHostRefApi}checklist/${this.checklistId}/edit?is_api=1&api_project_id=${projectId}`)
          .then((response) => {
            if (response && response.data) {
              this.checklistProjectData = response.data.projectdata
            }
          })
          .finally(() => {
          })
      }
    },
    onSuccessHandler (payload) {
      if (payload.status === 'success') {
        let text = ''
        if (payload.data && payload.action === 'update') text = 'message.common.updatedSuccess'
        else if (payload.data && payload.action === 'add') text = 'message.common.addedSucess'
        if (this.checklistId) this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        if (payload.action === 'add') this.saveChecklistData(payload.data, payload.action)
        else if (payload.action === 'update') this.saveChecklistData(payload.data, payload.action)
      } else if (payload.status === 'error') {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: payload.data.response.data })
      }
    },
    saveChecklistData (checklist, action) {
      let url = ''
      let method = ''
      let text = ''
      let userId = this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0
      if (checklist._id && action === 'update') {
        url = `checklists/${checklist._id}`
        method = 'put'
        text = 'message.common.updatedSuccess'
      } 
      else {
        url = 'checklists'
        method = 'post'
        text = 'message.common.addedSucess'
      }
      if (method === 'post') {
        let checklistObj = {
          checklist_api_id: checklist._id,
          checklist_name: this.checklistTitle,
          report_header: this.report_header,
          checklist_recipients: this.checklistRecipients.toString(),
          checklist_template_id: this.selectedItemId,
          user_id: parseInt(userId),
          status: 1
        }
        const projectId = this.selectedProjectId || this.$route.params[this.propId]
        switch (this.module) {
          case 'global':
            checklistObj.object_type = 1
            checklistObj.api_object_id = this.moduleID ? this.moduleID : 0
            break
          case 'project':
            checklistObj.object_type = this.moduleType ? this.moduleType : 2
            checklistObj.api_object_id = this.moduleID ? this.moduleID : 0
            checklistObj.api_project_id = parseInt(projectId)
            break
          case 'equipment':
            checklistObj.object_type = 6
            checklistObj.api_object_id = parseInt(this.$route.params[this.propId])
            break
          case 'chemicallist':
            checklistObj.object_type = 5
            checklistObj.api_object_id = parseInt(this.$route.params[this.propId])
            break
          default:
            break
        }
        hostAppApi[method](`https://${this.hostRef}/api/checklist`, checklistObj)
          .then((response) => {
            if (this.checklistId) this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
            if (response.data) {
              this.checklistProjectData = response.data.projectdata
              this.$eventBus.$emit('setChecklistId', response.data.checklist_api_id)
            }
          })
      }
      if (method === 'put') {
        let obj = { checklist_template_id: this.selectedItemId, checklist_name: this.checklistTitle, checklist_recipients : this.checklistRecipients.toString(), report_header : this.report_header}
        hostAppApi[method](`https://${this.hostRef}/api/checklist/${checklist._id}`, obj)
          .then((response) => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
            if (response.data) {}
          })
      }
    },
    backToList () {
      this.$eventBus.$emit('closeChecklistDialog')
    }
  },
  watch: {
    async search_email (val) {
      if(!val || val.length < 2) return
      await hostAppApi
        .post(`https://${this.hostRef}/api/checklist/search-recipient?is_api=1`, {'search' : val})
        .then((response) => {
          if (response.status == 200) {
            this.emailRecipients = response.data.emails
          }
        })
        .catch((error) => {});
      }
  }
}
</script>
