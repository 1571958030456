import { fieldTypeConstants } from '@/utils/support_list'
import lodash from 'lodash'
import moment from 'moment'
export default {
  data () {
    return {
      fieldTypeConstants: fieldTypeConstants,
      datagridHeaders: [],
      watchListFieldsCalled: 0,
      showForm: true,
      self: this,
      eventaddedIds: []
    }
  },
  methods: {
    dgEventHandler (evt, index, logic, rowIndex, datagridName) {
      const dataGridRowData = this.getDataConstructedForScript(datagridName, rowIndex)
      const element = this.$formatter.cloneVariable(this.datagridHeaders[index])
      // eslint-disable-next-line
      let logicalFunction = new Function('instance', 'input', 'data', element[`logic_${logic.logicname}`])
      logicalFunction(this, evt.target.value, dataGridRowData)
    },
    /* Helper methods */
    // Decode the String
    decodeStringTobase64 (string) {
      if (string) {
        var decodedString = atob(string)
        return decodedString
      }
    },
    /* Extension Methods */
    // Extension get method
    getDataGridProperty (key, rowIndex, datagridName) {
      let result = ''
      result = this.getSetValue(key, rowIndex, datagridName, 'get') // get // set
      if (result === undefined || result === null) result = ''
      return result
    },
    // Extension set method
    setDataGridProperty (key, value, rowIndex, datagridName) {
      this.getSetValue(key, rowIndex, datagridName, 'set', value)
    },
    showAlert (type, message) {
      this.$root.$emit('snackbar', { snackbar: true, color: type, text: message })
    },
    getDataGridList (field, rowIndex, datagridName) {
      const components = this.aliasFieldsList.filter(x => x.type === 18)
      const dgObj = components.find(x => x.name === datagridName)
      let result = []
      if (dgObj) {
        const labels = lodash.get(dgObj, 'default_value.grid_data.labels')
        const label = labels.find(x => x.name === field)
        const fieldType = lodash.get(label, 'field.type')
        if (fieldType === 'select') {
          const typedata = lodash.get(label, 'field.typedata')
          result = typedata.selectbox_type === 'custom' ? typedata.customOptions : typedata.listItems
        }
      }
      return result || []
    },
    // Extension set select options method
    setDataGridOptionList (key, value, itemValue, rowIndex, datagridName) {
      const index = rowIndex.split('row_')[1]
      const components = this.aliasFieldsList.filter(x => x.type === 18)
      const dgObj = components.find(x => x.name === datagridName)
      if (dgObj) {
        const labels = lodash.get(dgObj, 'default_value.grid_data.labels')
        const label = labels.find(x => x.name === key)
        if (label) {
          label.field.typedata = {
            ...label.field.typedata,
            ...{
              customOptions: value,
              optionName: itemValue,
              optionValue: itemValue
            }
          }
          this.$set(this.aliasFieldsList, index, dgObj)
        }
      }
    },
    addGridFirstRow_0 (size, gridDataObj) {
      const labels = gridDataObj.labels
      const dataRowValues = gridDataObj.data
      const getGrid = dataRowValues
      const gridData = []
      for (let i = 0; i < size; i++) {
        let value = ''
        if (labels[i].field.type === this.fieldTypeConstants.DATE) {
          const defaultValueKeyword = lodash.get(labels[i], 'field.typedata.dateDefaultValueKeyword')
          if (defaultValueKeyword && defaultValueKeyword === 'CURDATE') value = moment().format('DD.MM.YYYY')
        } else {
          let defauleValue = lodash.get(labels[i], 'field.typedata.default_value')
          if (defauleValue) {
            if (labels[i].field.type === this.fieldTypeConstants.CHECKBOX) defauleValue = [defauleValue]
            value = defauleValue
          }
        }
        gridData.push({ value })
      }
      this.checkAndSetEventHandlers('row_0') // index
      return { data: Object.assign({ ...getGrid }, { [`row_${0}`]: [...gridData] }), rowIndex: 'row_0' }
    },
    checkAndSetEventHandlers (rowIndex) {
      const components = this.aliasFieldsList.filter(x => x.type === 18) // get only datadrid component
      components.forEach(component => {
        const datagridName = component.name || ''
        this.datagridHeaders = lodash.get(component, 'default_value.grid_data.labels')
        this.datagridHeaders = this.datagridHeaders || []
        const labels = this.datagridHeaders
        labels.forEach((element, index) => {
          const logicalData = lodash.get(element, 'field.logicaldata')

          // Logical manipulation
          if (logicalData && logicalData.logicscripts && logicalData.logicscripts.length > 0) {
            logicalData.logicscripts.forEach(logic => {
              if (!logic.logicevent || !logic.logicscript || !logic.logicname) return
              let logicScript = this.decodeStringTobase64(logic.logicscript)
              if (logicScript) logicScript = this.addGridParamasToFunc(logicScript, rowIndex, datagridName)
              if (element.field.type === this.fieldTypeConstants.SELECT && logic.logicevent === 'change') {
                element[`logic_${logic.logicname}`] = logicScript
              } else {
                switch (element.field.type) {
                  case this.fieldTypeConstants.RADIOBOX:
                  case this.fieldTypeConstants.CHECKBOX:
                    break
                  default:
                    element[`logic_${logic.logicname}`] = logicScript
                    // eslint-disable-next-line
                    setTimeout(() => {
                      const elementItem = this.$el.querySelector(`#_${element.name}_${rowIndex}`)
                      if (this.eventaddedIds.includes(`#_${element.name}_${rowIndex}`)) return
                      if (elementItem) {
                        this.eventaddedIds.push(`#_${element.name}_${rowIndex}`)
                        elementItem.addEventListener(logic.logicevent, (event) => {
                          this.dgEventHandler(event, index, logic, rowIndex, datagridName)
                        })
                      }
                    }, 500)
                    break
                }
              }
            })
          }
          const conditionalData = lodash.get(element, 'field.conditionaldata')
          // Conditional manipulation
          if (conditionalData && conditionalData.conditionscript) {
            let conditionScript = this.decodeStringTobase64(conditionalData.conditionscript)
            /* run condititon script for default value set */
            if (conditionScript) conditionScript = this.addGridParamasToFunc(conditionScript, rowIndex, datagridName)
            /* eslint-disable no-new-func */
            const loadScript = new Function('data', 'instance', conditionScript)
            setTimeout(() => {
              loadScript(this.fields, this)
            }, 1000)
          }
        })
      })
    },
    addGridParamasToFunc (logicScript, rowIndex, datagridName) {
      let script = ''
      const scriptArray = logicScript.match(/[^\r\n]+/g)
      const resultArray = []
      scriptArray.forEach((singleLine) => {
        const extensionMethods = ['getDataGridProperty', 'setDataGridProperty', 'setDataGridOptionList', 'getDataGridList']
        extensionMethods.forEach((method) => {
          const hasMethod = this.countOccurences(singleLine, method)
          if (hasMethod) {
            var stringBetween = singleLine.match(`(?=${method}).*(?=${this.escapedCloseBracket})`)
            stringBetween = stringBetween[0].replace(')', '')
            // var stringBetween = logicScript.match(`${method}((.*)')`)
            const existingFunction = `${stringBetween})`
            const replacingFunction = `${stringBetween}, '${rowIndex}', '${datagridName}')`
            singleLine = singleLine.replace(existingFunction, replacingFunction)
          }
        })
        resultArray.push(singleLine)
      })
      script = resultArray.join('\n')
      return script
      /* Step 1 split string to array by newline */
      /* Step 2 check each line for method existence */
      /* Step 3 add programmatic params for extension methods based on the method requirement */
    },
    /* Conditional Based show hide whether field // conditionscript */
    checkAndAddConditionScripts (rowIndex, element) {
      const components = this.aliasFieldsList.filter(x => x.type === 18) // get only datadrid component
      components.forEach(component => {
        const datagridName = component.name || ''
        let labels = lodash.get(component, 'default_value.grid_data.labels')
        labels = labels || []
        labels.forEach((label, colIndex) => {
          if (element && element[rowIndex]) {
            if (element[rowIndex][colIndex]) {
              element[rowIndex][colIndex].isShow = true // by default show all fields
            }
          }
          const conditionalData = lodash.get(label, 'field.conditionaldata')
          if (conditionalData) {
            // Simple Conditional
            if (conditionalData.fielddisplayconditionwhen) {
              const foundElementIndex = labels.findIndex(x => x.name === conditionalData.fielddisplayconditionwhen)
              if (foundElementIndex !== -1) {
                if (conditionalData.fielddisplaycondition) {
                  if (element && element[rowIndex]) {
                    if (element[rowIndex][colIndex]) {
                      element[rowIndex][colIndex].isShow = false // if show based on condition then hide by default
                    }
                  }
                }
                if (element && element[rowIndex]) {
                  if (element[rowIndex][foundElementIndex]) {
                    element[rowIndex][foundElementIndex].hideField = label.name
                    element[rowIndex][foundElementIndex].hideConditions = {
                      condition: conditionalData.fielddisplaycondition,
                      value: conditionalData.fielddisplayconditionvalue
                    }
                  }
                }
              }
            }
            // Conditional Script manipulation
            if (conditionalData.conditionscript) {
              let conditionScript = this.decodeStringTobase64(conditionalData.conditionscript)
              if (this.hasConditionScript(conditionScript)) {
                if (conditionScript) conditionScript = this.addGridParamasToFunc(conditionScript, rowIndex, datagridName)
                conditionScript = `var show=true\n${conditionScript} \nreturn show`
                // eslint-disable-next-line
                element[rowIndex][colIndex].isShowElement = new Function('data', 'instance', conditionScript)
              }
            }
          }
        })
      })
    },
    /* Methods for datagrid ---- */
    countOccurences (string, word) {
      return string.split(word).length - 1
    },
    getSetValue (key, rowIndex, datagridName, type, value) { // get, set
      const dgGrid = this.getDataGridLabelsAndData(datagridName)
      if (dgGrid) {
        const columnIndex = dgGrid.labels.findIndex(x => x.name === key)
        if (columnIndex !== -1) {
          if (dgGrid.labels[columnIndex].field.type) {
            switch (dgGrid.labels[columnIndex].field.type) {
              case this.fieldTypeConstants.CHECKBOX:
                value = [value]
                break
              default:
                break
            }
          }
          if (type === 'get') {
            let value = dgGrid.data[rowIndex][columnIndex].value
            if (dgGrid.labels[columnIndex].field.type === this.fieldTypeConstants.CHECKBOX) value = lodash.compact(value).join(',')
            return value
          } else if (type === 'set') dgGrid.data[rowIndex][columnIndex].value = value
        }
      }
    },
    getDataGridLabelsAndData (datagridName) {
      let obj = null
      const components = this.aliasFieldsList.filter(x => x.type === 18)
      const dgObj = components.find(x => x.name === datagridName)
      if (dgObj) {
        obj = {}
        obj.labels = lodash.get(dgObj, 'default_value.grid_data.labels')
        obj.data = lodash.get(dgObj, 'default_value.grid_data.data')
      }
      return obj
    },
    checkShowHideFunctionOnChange (element, value, rowIndex, gridItem = null, colIndex = -1) { // index can begin with 0
      if (element.isShowElement) element.isShowElement(element, this)
      element.conditionscript = () => {}
      // Conditional hide
      if (element.hideField) {
        /* get list of components & loop each */
        // let components = this.aliasFieldsList.filter(x => x.type === 18) // get only datadrid component
        this.aliasFieldsList.forEach((component, index) => {
          if (component.type === 18) {
            const labels = lodash.get(component, 'default_value.grid_data.labels')
            const data = lodash.get(component, 'default_value.grid_data.data')
            const foundElementIndex = labels.findIndex(x => x.name === element.hideField)
            if (colIndex > -1 && lodash.get(labels[colIndex], 'field.type') && lodash.get(labels[colIndex], 'field.type') === this.fieldTypeConstants.CHECKBOX) value = lodash.compact(value).join(',')
            data[rowIndex][foundElementIndex].isShow = element.hideConditions.value === value ? element.hideConditions.condition : !element.hideConditions.condition
          }
          this.$set(this.aliasFieldsList, index, component)
        })
      }
      // Onchange select logic script
      if (element.type === this.fieldTypeConstants.SELECT && element.logicscripts && element.logicscripts.length > 0) {
        if (element.logicscripts.length > 0) {
          const haschangeEvent = element.logicscripts.find(x => x.logicevent === 'change' || x.logicevent === 'onchange')
          if (haschangeEvent) {
            // eslint-disable-next-line
            let logicalFunction = new Function('instance', 'input', 'data', element[haschangeEvent.logicname])
            logicalFunction(this, value, this.fields)
          }
        }
      }
      /* for checkbox and radio alone check change event from here */
      if (gridItem && colIndex > -1) { // either checkbox or radio
        const dataGrid = this.aliasFieldsList.find(x => x.type === 18 && x.name === gridItem.name)
        // let indexOf = this.aliasFieldsList.findIndex(x => x.type === 18 && x.name === gridItem.name )
        const labels = lodash.get(dataGrid, 'default_value.grid_data.labels')
        const logicalData = lodash.get(labels[colIndex], 'field.logicaldata')
        // Logical manipulation
        if (logicalData && logicalData.logicscripts && logicalData.logicscripts.length > 0) {
          logicalData.logicscripts.forEach(logic => {
            if (!logic.logicevent || !logic.logicscript || !logic.logicname) return
            if (!['change', 'onchange', 'input', 'oninput'].includes(logic.logicevent)) return
            /* if script will execute method then construct data */
            const dataGridRowData = this.getDataConstructedForScript(gridItem.name, rowIndex)
            let logicScript = this.decodeStringTobase64(logic.logicscript)
            if (logicScript) logicScript = this.addGridParamasToFunc(logicScript, rowIndex, gridItem.name)
            switch (labels[colIndex].field.type) {
              case this.fieldTypeConstants.RADIOBOX:
              case this.fieldTypeConstants.CHECKBOX:
                var logicalFunction = new Function('instance', 'input', 'data', logicScript)
                var value = this.getDataGridProperty(labels[colIndex].name, rowIndex, gridItem.name)
                logicalFunction(this, value, dataGridRowData)
                break
            }
          })
        }
      }
    },
    triggerDatePickerChangeEvent (el) {
      var element = document.getElementById(el)
      if (element) {
        var event = new Event('change')
        element.dispatchEvent(event)
      }
    },
    getDataConstructedForScript (datagridName, rowIndex) {
      const dataGrid = this.aliasFieldsList.find(x => x.type === 18 && x.name === datagridName)
      const data = lodash.get(dataGrid, 'default_value.grid_data.data')
      const labels = lodash.get(dataGrid, 'default_value.grid_data.labels')
      const resultData = {}
      labels.forEach((label, colIndex) => {
        let value = data[rowIndex][colIndex].value
        if (label.field.type === this.fieldTypeConstants.CHECKBOX) value = lodash.compact(value).join(',')
        resultData[label.name] = value
      })
      return resultData
    }
  },
  watch: {
    aliasFieldsList (val) {
      if (val && !this.watchListFieldsCalled) {
        this.aliasFieldsList.forEach((element, index) => {
          if (element.type === 18) {
            this.showForm = false
            const defaultValue = element.default_value
            if (defaultValue.grid_data && Object.keys(defaultValue.grid_data.data).length === 0) {
              const obj = this.addGridFirstRow_0(+element.label, defaultValue.grid_data)
              element.default_value.grid_data.data = obj.data
              const rowIndex = obj.rowIndex
              this.checkAndAddConditionScripts(rowIndex, element.default_value.grid_data.data)
            } else if (element.default_value.grid_data && Object.keys(element.default_value.grid_data.data).length > 0) {
              // if already has data and td length differs from value then add extra empty value object
              const gridData = element.default_value.grid_data.data
              const length = !this.$formatter.isEmptyObject(gridData) ? Object.keys(gridData).length : 0
              if (length) {
                const lastRowString = `row_${length - 1}`
                const rowCols = element.default_value.grid_data.data[lastRowString]
                const labels = element.default_value.grid_data.labels
                // compare rowcols length with header lables length
                if (labels && rowCols && labels.length > rowCols.length) {
                  const emptyValueArray = [{ value: '' }]
                  Object.keys(gridData).forEach((key) => {
                    gridData[key] = this.$formatter.cloneVariable([...gridData[key], ...emptyValueArray])
                  })
                } else if (labels && rowCols && labels.length < rowCols.length) {
                  // if less then data remove not implemented (for now)
                }
              }
            }
            this.$set(this.aliasFieldsList, index, element)
            this.showForm = true
          }
        })
        this.watchListFieldsCalled++
      }
      if (this.checklistId) {
        const components = this.aliasFieldsList.filter(x => x.type === 18) // get only datadrid component
        components.forEach(component => {
          const componentData = this.fields[component.name] || null
          if (componentData) {
            for (const key in componentData) {
              if (Object.hasOwnProperty.call(componentData, key)) {
                this.checkAndSetEventHandlers(key)
              }
            }
          }
        })
      }
    }
  }
}
