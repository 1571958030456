export default {
  methods: {
    surveyLogicScriptEncodeOrDecode (codeType, surveyLogicScripts = this.surveyLogicScripts, isFromRender, fieldIndex) { // isFromRender & fieldIndex is for render page
      const type = codeType === 1 ? '$_encodeStringTobase64' : '$_decodeStringTobase64'
      // 3 properties in object answerLogicscripts, dateLogicscripts, signatureLogicscripts
      const scripts = this.$formatter.cloneVariable(surveyLogicScripts)
      Object.keys(scripts).forEach(key => {
        switch (key) {
          case 'answerLogicscripts':
          case 'dateLogicscripts':
            var value = scripts[key]
            value.logicscript = value.logicscript ? this.$formatter[type](value.logicscript) : ''
            if (isFromRender && value.logicscript) {
              const logicfield = key.replace('Logicscripts', '')
              this.aliasFieldsList[fieldIndex][`${logicfield}_logic_${value.logicname}`] = value.logicscript
              this.$watch(`fields.${this.aliasFieldsList[fieldIndex].name}.${logicfield}`, function (inputValue) {
                this.surveyEventHandler(inputValue, fieldIndex, value, logicfield)
              })
            }
            break
          case 'signatureLogicscripts':
            var values = scripts[key]
            values.forEach((value) => {
              value.logicscript = value.logicscript ? this.$formatter[type](value.logicscript) : ''
              if (isFromRender && value.logicscript) {
                this.aliasFieldsList[fieldIndex][`signature_logic_${value.logicname}`] = value.logicscript
                // eslint-disable-next-line
                setTimeout(() => {
                  const elementItem = this.$el.querySelector(`#_${this.aliasFieldsList[fieldIndex].name}_signature`)
                  if (elementItem) {
                    elementItem.addEventListener(value.logicevent, (event) => {
                      this.surveyEventHandler(event.target.value, fieldIndex, value, 'signature')
                    })
                  }
                }, 1000)
              }
            })
            break
        }
      })
      return scripts
    },
    surveyEventHandler (inputValue, index, logic, logicField) {
      const element = this.$formatter.cloneVariable(this.aliasFieldsList[index])
      // eslint-disable-next-line
      let logicScript = element[`${logicField}_logic_${logic.logicname}`]
      if (logicScript) {
        const scriptArray = logicScript.match(/[^\r\n]+/g)
        const resultArray = []
        scriptArray.forEach((singleLine) => {
          const extensionMethods = ['getSurveyProperty', 'setSurveyProperty']
          extensionMethods.forEach((method) => {
            const hasMethod = this.countOccurences(singleLine, method)
            if (hasMethod) {
              var stringBetween = singleLine.match(`(?=${method}).*(?=${this.escapedCloseBracket})`)
              stringBetween = stringBetween[0].replace(')', '')
              const existingFunction = `${stringBetween})`
              const replacingFunction = `${stringBetween}, '${element.name}')`
              singleLine = singleLine.replace(existingFunction, replacingFunction)
            }
          })
          resultArray.push(singleLine)
        })
        logicScript = resultArray.join('\n')
      }
      /* eslint-disable no-new-func */
      const logicalFunction = new Function('instance', 'input', 'surveydata', logicScript)
      logicalFunction(this, inputValue, this.fields[this.aliasFieldsList[index].name])
    },
    countOccurences (string, word) {
      return string.split(word).length - 1
    },
    // Extension get method
    getSurveyProperty (key, surveyFieldName) {
      return this.fields[surveyFieldName][key]
    },
    // Extension set method
    setSurveyProperty (key, value, surveyFieldName) {
      this.fields[surveyFieldName][key] = value
    }
  }
}
